import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import dogIllustration from "../images/dog-illustration.svg";
import Section from "../components/atoms/section";
import Page from "../components/atoms/page";
import Main from "../components/atoms/main";

function AboutPage() {

    const title = 'Some title here!';
    return (
        <Layout useMain={true}>
            <SEO
                title={title}
                keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
            />
            <Page title={title}>
                The point is... to live one's life in the full complexity of what
                one is, which is something much darker, more contradictory, more of
                a maelstrom of impulses and passions, of cruelty, ecstacy, and
                madness, than is apparent to the civilized being who glides on the
                surface and fits smoothly into the world.
            </Page>
        </Layout>
    );
}

export default AboutPage;
